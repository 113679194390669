import React from 'react'
import styles from './404.module.scss'
import Landing from '../components/landing/landing'
import Footer from '../components/footer/footer'

const NotFound = () => (
  <div className="container-fluid">
    <Landing />
    <div className={`${styles.content} text-center`}>
      <h1 className={styles.header}>Page not found</h1>
      <p className={styles.errorMessage}>The page you are looking for does not exists.</p>
    </div>
    <Footer />
  </div>
)
export default NotFound
